.AnalysisBox {
  margin-top: -0.3rem;
  padding: 0.3rem 1rem;
  font-weight: 500;
  white-space: nowrap;
  color: white;
  border-radius: 0.5rem;
}

.Complete {
  background-color: #113063;
}

.Incomplete {
  background-color: #11306380;
  outline: 1px dashed #113063;
  outline-offset: -1px;
}

.QSN {
  background-color: #ffff00;
  color: #113063;
}

.QS {
  background-color: #ff5aac;
  color: white;
}

.QN {
  background-color: #8a00c2;
  color: white;
}

.QSC {
  background-color: #0b2919;
  color: white;
}

.Q {
  background-color: #00ccff;
  color: #113063;
}

.N {
  background-color: #ffa500;
  color: #113063;
}

.C {
  background-color: #000000;
  color: white;
}

.NC {
  background-color: #3c783f;
  color: white;
}

.SNC {
  background-color: #3642c3;
  color: white;
}

.SC {
  background-color: #712c05;
  color: white;
}

.S {
  background-color: #67d423;
  color: white;
}